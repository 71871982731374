import { Injectable } from '@angular/core';
import { AuthService } from '@memberspot/admin/shared/data-access/auth';
import { SchoolsQuery } from '@memberspot/admin/shared/data-access/schools';
import { PreviewTokenReturnData } from '@memberspot/models';
import { combineLatest, of, timer } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  retryWhen,
  shareReplay,
  switchMap,
  take,
} from 'rxjs/operators';

import { SchoolUrlService } from './school-url.service';

@Injectable({
  providedIn: 'root',
})
export class PreviewService {
  token$ = this._schoolsQuery.selectActive().pipe(
    map((school) => school?.id),
    filter(
      (schoolId): schoolId is string =>
        !!schoolId && typeof schoolId === 'string',
    ),
    distinctUntilChanged(),
    switchMap((schoolId) =>
      // token expires after 60 minutes
      combineLatest([of(schoolId), timer(100, 3500000)]),
    ),
    switchMap(([schoolId]) => this._authService.getDemoUserToken(schoolId)),
    retryWhen((errors) => errors.pipe(delay(20000), take(10))),
    shareReplay(1),
  );

  get clientUrl() {
    return this._urlService.getClientUrl();
  }

  constructor(
    private _schoolsQuery: SchoolsQuery,
    private _authService: AuthService,
    private _urlService: SchoolUrlService,
  ) {
    this.token$.subscribe();
  }

  goToPostDetails(courseId: string, chapterId: string, postId: string) {
    this.goToLiveVersion(
      this._urlService.getRealtivePostDetailUrl(courseId, chapterId, postId),
    );
  }

  goToLiveVersion(goToUrl?: string) {
    this.token$
      .pipe(
        first(),
        map((res) => this._createUrl(res, goToUrl)),
      )
      .subscribe((url) => {
        window.open(url, '_blank');
      });
  }

  loginAsUser(schoolId: string, uid: string) {
    this._authService
      .getUserToken(schoolId, uid)
      .pipe(
        first(),
        map((tokenResult) =>
          this._addUserTokenToUrl(this._createStartPageUrl(), tokenResult),
        ),
      )
      .subscribe((url) => {
        window.open(url, '_blank');
      });
  }

  private _createUrl(token: PreviewTokenReturnData, goToUrl?: string) {
    const url = goToUrl
      ? this._createAddToUrl(goToUrl)
      : this._createStartPageUrl();

    return this._addUserTokenToUrl(url, token);
  }

  private _createStartPageUrl() {
    const url = this._getClientUrl() + 'startpage';

    return url;
  }

  private _createAddToUrl(add: string) {
    const baseUrl = this._getClientUrl();

    return baseUrl + add;
  }

  private _getClientUrl() {
    return `${this.clientUrl}/token?redirectTo=`;
  }

  private _addUserTokenToUrl(url: string, res: PreviewTokenReturnData) {
    return `${url}&token=${res.token}`;
  }
}
